(function($){
    $(window).on("load",function(){
      
        setTimeout(() => {
            $("#tabs").mCustomScrollbar({
                axis:"x",
                advanced:{
                  autoExpandHorizontalScroll:true
                }
              });
                    
        }, 3000);
      
    });
    
  })(jQuery);

  

  function googleTranslateElementInit() {
    
    setTimeout(()=>{
      new google.translate.TranslateElement({pageLanguage: 'en', includedLanguages: "en,fr,da,de,el,it,nl,ro,sv,ar,es,hi,th,tr,ua,pa", layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element');

      setTimeout(()=>{
        
        $("iframe").contents().find(".goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div") 
                .css({
                    'color': '#1e1e1e',
                    
                });                
      },1000);

    }, 1000)
  }

 